<template>
  <div class="page">
    <div class="main">
      <Load v-if="isLoading"></Load>
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { userApi } from "@/http";
import Load from "@/components/ui/Load";

const store = useStore();
const isLoading = ref(true);

store
  .dispatch("user/login")
  .then((res) => {
    isLoading.value = false;
  })
  .catch((err) => {
    console.log(err);
  });
</script>

<style>
.page {
  width: 100vw;
  height: 100vh;
  background-color: #1c1c1d;
}

.main {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  height: 100%;
  /* background: radial-gradient(
    circle at 50% 75%,
    #322e22 0%,
    #302310 50%,
    #010101 70%
  ); */
}

#bg {
  position: absolute;
  z-index: 0;
}
</style>
