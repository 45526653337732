import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'market',
    component: ()=>import('../pages/market/index.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: ()=>import('../pages/order/index.vue')
  },
  {
    path: '/createAd',
    name: 'createAd',
    component: ()=>import('../pages/createAd/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router