<template>
  <div
    class="load-wrapper flex-column flex-center flex-middle"
    :class="mask ? 'position-fixed' : 'position-absolute'"
  >
    <div v-if="mask"
      class="mask"
      @touchmove.prevent=""
      :style="{ background: maskBackgroundColor }"
    ></div>
    <div class="tips position-relative" v-if="tips">{{ tips }}</div>
    <canvas class="load position-relative" ref="load"></canvas>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted, defineProps } from "vue";
import { rem2px } from "@/assets/js/tool";

const load = ref();
const props = defineProps({
  mask: {
    type: Boolean,
    default: false,
  },
  maskBackgroundColor: {
    type: String,
    default: "rgba(0,0,0,.4)",
  },
  // rgb
  dotColor:{
    type:String,
    default:'81,133,238'
  },
  tips: String,
});
let canvas;

class LoadDots {
  constructor() {
    this.value = [
      {
        x: rem2px(300),
        y: rem2px(52),
        fixedX: rem2px(300),
        fixedY: rem2px(52),
      },
      {
        x: rem2px(375),
        y: rem2px(52),
        fixedX: rem2px(375),
        fixedY: rem2px(52),
      },
      {
        x: rem2px(450),
        y: rem2px(52),
        fixedX: rem2px(450),
        fixedY: rem2px(52),
      },
    ];
    this.r = rem2px(16);
    this.t = 0;
    this.interval = 120;
    this.stage = 1;
  }

  updateDot(dot, i) {
    const { value, r } = this;

    switch (this.stage) {
      case 0:
        this.t++;
        if (this.t >= this.interval) {
          this.stage = 1;
          this.t = 0;
        }
        break;
      case 1:
        this.updateStage1();
        break;
      case 2:
        this.updateStage2(dot, i);
        break;
      case 3:
        this.updateStage3(dot, i);
        break;
    }
  }

  updateStage1() {
    const dot1 = this.value[0];
    if (dot1.fixedY - dot1.y <= 2 * this.r) {
      dot1.y--;
    } else {
      this.stage = 2;
    }
  }

  updateStage2(dot, i) {
    let lastDot = this.value[i > 0 ? i - 1 : this.value.length - 1];
    let d = dot.x - lastDot.fixedX;
    if (d > 0) {
      dot.x -= d > 2 ? 2 : d;
    } else if (d < 0) {
      dot.x += d < -4 ? 4 : -d;
    } else {
      this.stage = 3;
    }
  }

  updateStage3(dot, i) {
    const dot1 = this.value[0];
    if (dot1.fixedY > dot1.y) {
      dot1.y++;
    } else if (i === this.value.length - 1) {
      this.reset();
    }
  }

  reset() {
    this.value.forEach((ite) => {
      ite.x = ite.fixedX;
      ite.y = ite.fixedY;
    });
    this.stage = 0;
  }
}

class CanvasAnimation {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = this.canvas.getContext("2d");
    this.animationId = 0;
    this.loadDots = new LoadDots();
    this.initSize();
    this.startAnimationFrame();
  }

  initSize() {
    this.updateSize();
    window.addEventListener("resize", this.updateSize.bind(this), false);
  }

  updateSize() {
    const windowW = window.innerWidth;
    let width = (this.canvas.width =
      windowW < 320 ? 320 : windowW > 480 ? 480 : windowW);
    let height = (this.canvas.height = rem2px(68));

    this.size = {
      width,
      height,
      windowWidth: windowW,
    };
  }

  startAnimationFrame() {
    if (this.animationId) cancelAnimationFrame(this.animationId);
    this.animationId = requestAnimationFrame(this.animationFrame.bind(this));
  }

  animationFrame() {
    this.ctx.clearRect(0, 0, this.size.width, this.size.height);
    this.drawLoad();
    this.animationId = requestAnimationFrame(this.animationFrame.bind(this));
  }

  drawLoad() {
    const { ctx, loadDots } = this;
    loadDots.value.forEach((ite, idx) => {
      ctx.beginPath();
      ctx.arc(ite.x, ite.y, loadDots.r, 0, 2 * Math.PI, true);
      if (loadDots.stage && idx === 0) ctx.fillStyle = `rgb(${props.dotColor},0.4)`;
      else ctx.fillStyle = `rgb(${props.dotColor})`;
      loadDots.updateDot(ite, idx);
      ctx.fill();
    });
  }

  unmount() {
    cancelAnimationFrame(this.animationId);
  }
}

onMounted(() => {
  canvas = new CanvasAnimation(load.value);
});

onBeforeUnmount(() => {
  canvas.unmount();
});
</script>

<style lang="scss" scoped>
.load-wrapper {
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 9;

  .tips {
    padding: 0 40rem;
    text-align: center;
    font-size: 38rem;
    color: #fff;
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>