import axios from '@/http/axios'

function login(data) {
    return axios({
        url: '/auth/login',
        method: 'post',
        data
    })
}

export default {
    login,
}