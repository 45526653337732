import { userApi } from '@/http'
import tw from '@/assets/js/tw'
import { test } from "@/config/global";
import Cookies from "js-cookie";

export default {
    namespaced: true,
    state: {
        appname: 'dotcoin'
    },
    mutations: {
    },
    actions: {
        login(context) {
            tw.init();
            let initData = tw.WebApp.initData;
            initData = initData ? initData : "1";

            const data = {
                data: initData,
                test,
                appname:context.state.appname
            }

            return new Promise((resolve, reject) => {
                userApi.login(data)
                    .then((res) => {
                        Cookies.set('token', res.result.token)
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
    getters: {}
}