import { baseURL } from '@/config/global'

export default {
    method: 'get',
    baseURL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
      // 'Content-Type': 'application/json'
    },
    data: {},
    // timeout: 10000,
    // Accept:'*/*',
    // withCredentials: false,
    // responseType: 'json'
}